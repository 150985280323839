import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//连续点击多次路由报错解决方法
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  routes: [
    {
      path: "/serviceCenter",
      name: "serviceCenter",
      component: () => import("@/views/ServiceCenter.vue"),
      meta: {
        title: "客服中心",
        active: "serviceCenter",
      },
    },
    {
      path: "/hmlist/:taskId",
      name: "hmlist",
      component: () => import("@/views/hmlist.vue"),
      meta: {
        title: "合卖会员",
        active: "hmList",
      },
      props: true,
    },
    {
      path: "/amazon",
      name: "amazon",
      component: () => import("@/views/Amazon.vue"),
    },
    {
      path: "/profit",
      name: "profit",
      component: () => import("@/views/Profit.vue"),
      meta: {
        title: "会员收益",
        active: "profit",
      },
    },
    {
      path: "/help",
      name: "help",
      component: () => import("@/views/Help.vue"),
      meta: {
        title: "帮助手册",
        active: "help",
      },
    },
    {
      path: "/busauth",
      name: "busauth",
      component: () => import("@/views/BusAuth.vue"),
      meta: {
        title: "商家认证",
        active: "busauth",
      },
    },
    {
      path: "/shimingauth",
      name: "shimingauth",
      component: () => import("@/views/ShimingAuth.vue"),
      meta: {
        title: "实名认证",
        active: "shimingauth",
      },
    },
    {
      path: "/msglist",
      name: "msglist",
      component: () => import("@/views/user/MsgList.vue"),
      meta: {
        title: "消息列表",
        active: "msglist",
      },
    },
    {
      path: "/busup",
      name: "busup",
      component: () => import("@/views/BusUp.vue"),
      meta: {
        title: "商家升级",
        active: "busup",
      },
    },
    {
      path: "/errauth",
      name: "errauth",
      component: () => import("@/views/ErrAuth.vue"),
      meta: {
        title: "违规处理",
        active: "errauth",
      },
    },
    {
      path: "/hall",
      name: "hall",
      component: () => import("@/views/Hall.vue"),
      meta: {
        title: "大厅",
        active: "hall",
      },
    },
    {
      path: "/sell",
      name: "sell",
      component: () => import("@/views/Sell.vue"),
      meta: {
        title: "我要卖",
        active: "sell",
      },
    },
    {
      path: "/buy",
      name: "buy",
      component: () => import("@/views/Buy.vue"),
      meta: {
        title: "我要买",
        active: "buy",
      },
    },
    {
      path: "/article/:articleType/:articleId?",
      name: "article",
      component: () => import("@/views/Article.vue"),
      meta: {
        title: "内容",
        active: "article",
      },
      props: true,
    },
    {
      path: "/service",
      name: "service",
      component: () => import("@/views/Service.vue"),
      meta: {
        title: "在线客服",
        active: "service",
      },
    },
    {
      path: "/line",
      name: "line",
      component: () => import("@/views/Line.vue"),
      meta: {
        title: "切换线路",
        active: "line",
      },
    },
    {
      path: "/language",
      name: "language",
      component: () => import("@/views/Language.vue"),
      meta: {
        title: "选择语言",
        active: "language",
      },
    },
    {
      path: "/login/:username?",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        title: "用户登录",
        active: "login",
      },
    },
    {
      path: "/loginVerify/:username?",
      name: "loginVerify",
      component: () => import("@/views/LoginVerify.vue"),
      meta: {
        title: "用户登录",
        active: "loginVerify",
      },
    },
    {
      path: "/innerpage",
      name: "innerpage",
      component: () => import("@/views/InnerPage.vue"),
      meta: {
        title: "客服页面",
        active: "innerpage",
      },
    },
    {
      path: "/test",
      name: "test",
      component: () => import("@/views/Test.vue"),
      meta: {
        title: "测试页面",
        active: "test",
      },
    },
    {
      path: "/resetpwd",
      name: "resetpwd",
      component: () => import("@/views/ResetPwd.vue"),
      meta: {
        title: "忘记密码",
        active: "resetpwd",
      },
    },
    {
      path: "/register/:recommendId?/:appRegister?",
      name: "register",
      component: () => import("@/views/Register.vue"),
      meta: {
        title: "用户注册",
        active: "register",
      },
      props: true,
    },
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        title: "首页",
        active: "home",
      },
    },
    {
      path: "/task/:tabsActive?",
      name: "task",
      component: () => import("@/views/task/index.vue"),
      meta: {
        title: "任务",
        active: "task",
      },
      props: true,
    },
    {
      path: "/GrabOrders",
      name: "GrabOrders",
      component: () => import("@/views/task/GrabOrders.vue"),
      meta: {
        title: "任务列表",
        active: "task",
      },
      props: true,
    },
    {
      path: "/taskShow/:taskId",
      name: "taskShow",
      component: () => import("@/views/task/show.vue"),
      meta: {
        title: "任务详情",
        active: "task",
      },
      props: true,
    },
    {
      path: "/taskDetail/:taskId",
      name: "taskDetail",
      component: () => import("@/views/task/detail.vue"),
      meta: {
        title: "任务详情",
        active: "task",
      },
      props: true,
    },
    {
      path: "/facelive",
      name: "facelive",
      component: () => import("@/views/FaceLive.vue"),
      meta: {
        title: "FaceLive",
        active: "facelive",
      },
    },
    {
      path: "/faceverify",
      name: "faceverify",
      component: () => import("@/views/FaceVerify.vue"),
      meta: {
        title: "FaceVerify",
        active: "faceverify",
      },
    },
    {
      path: "/faceverifymain",
      name: "faceverifymain",
      component: () => import("@/views/FaceVerifyMain.vue"),
      meta: {
        title: "FaceVerify",
        active: "faceverify",
      },
    },
    {
      path: "/vip",
      name: "vip",
      component: () => import("@/views/Vip.vue"),
      meta: {
        title: "VIP",
        // requiresAuth: true,
        active: "vip",
      },
    },
    {
      path: "/myTask/:taskState?",
      name: "myTask",
      component: () => import("@/views/MyTask.vue"),
      meta: {
        title: "我的任务",
        requiresAuth: true,
        active: "myTask",
      },
      props: true,
    },
    {
      path: "/appDown",
      name: "appDown",
      component: () => import("@/views/AppDown.vue"),
      meta: {
        title: "APP下载",
        active: "appDown",
      },
    },
    {
      path: "/user",
      name: "",
      component: () => import("@/views/UserCenter.vue"),
      meta: {
        requiresAuth: true,
        title: "会员中心",
        active: "user",
      },
      children: [
        {
          path: "",
          name: "user",
          component: () => import("@/views/user/index.vue"),
          meta: {
            title: "会员中心",
            active: "user",
          },
        },
        {
          path: "fin",
          name: "fin",
          component: () => import("@/views/user/fin.vue"),
          meta: {
            title: "资金日志",
            active: "finlist",
          },
        },
        {
          path: "finlist",
          name: "finlist",
          component: () => import("@/views/user/finlist.vue"),
          meta: {
            title: "理财记录",
            active: "finlist",
          },
        },
        {
          path: "info",
          name: "info",
          component: () => import("@/views/user/info.vue"),
          meta: {
            title: "个人信息",
            active: "user",
          },
        },
        {
          path: "set/:setType",
          name: "set",
          component: () => import("@/views/user/set.vue"),
          meta: {
            title: "用户设置",
            active: "user",
          },
          props: true,
        },
        {
          path: "postTask/:taskId?",
          name: "postTask",
          component: () => import("@/views/user/postTask.vue"),
          meta: {
            title: "发布任务",
          },
          props: true,
        },
        {
          path: "postRecord/:taskType?",
          name: "postRecord",
          component: () => import("@/views/user/postRecord.vue"),
          meta: {
            title: "发布管理",
          },
          props: true,
        },
        {
          path: "taskInfo/:taskId",
          name: "taskInfo",
          component: () => import("@/views/user/taskInfo.vue"),
          meta: {
            title: "任务详情",
          },
          props: true,
        },
        {
          path: "completeTheTask",
          name: "completeTheTask",
          component: () => import("@/views/user/completeTheTask.vue"),
          meta: {
            title: "完成任务",
          },
          props: true,
        },
        {
          path: "auditRecord/:taskState?",
          name: "auditRecord",
          component: () => import("@/views/user/auditRecord.vue"),
          meta: {
            title: "审核任务",
          },
          props: true,
        },
        {
          path: "auditInfo/:auditId",
          name: "auditInfo",
          component: () => import("@/views/user/auditInfo.vue"),
          meta: {
            title: "审核详情",
          },
          props: true,
        },
        {
          path: "dayReport",
          name: "dayReport",
          component: () => import("@/views/user/dayReport.vue"),
          meta: {
            title: "日结报表",
            active: "user",
          },
        },
        {
          path: "teamReport",
          name: "teamReport",
          component: () => import("@/views/user/teamReport.vue"),
          meta: {
            title: "团队报表",
            active: "user",
          },
        },
        {
          path: "fundRecord/:fundState?",
          name: "fundRecord",
          component: () => import("@/views/user/fundRecord.vue"),
          meta: {
            title: "账变记录",
            active: "user",
          },
          props: true,
        },
        {
          path: "bankCard",
          name: "bankCard",
          component: () => import("@/views/user/bankCard.vue"),
          meta: {
            title: "我的银行卡",
            active: "user",
          },
        },
        {
          path: "wallet/:walletType?",
          name: "wallet",
          component: () => import("@/views/user/wallet.vue"),
          meta: {
            title: "我的钱包",
            active: "user",
          },
          props: true,
        },
        {
          path: "recharge/:rechargeId?",
          name: "recharge",
          component: () => import("@/views/user/recharge.vue"),
          meta: {
            title: "我要充值",
            active: "user",
          },
          props: true,
        },
        {
          path: "promote/:isReward?",
          name: "promote",
          component: () => import("@/views/user/promote.vue"),
          meta: {
            title: "我的推广",
            active: "user",
          },
          props: true,
        },
        {
          path: "bindAccount",
          name: "bindAccount",
          component: () => import("@/views/user/bindAccount.vue"),
          meta: {
            title: "绑定账号",
            active: "user",
          },
        },
        {
          path: "credit",
          name: "credit",
          component: () => import("@/views/user/credit.vue"),
          meta: {
            title: "信用中心",
            active: "user",
          },
        },
        {
          path: "robot",
          name: "robot",
          component: () => import("@/views/user/robot.vue"),
          meta: {
            title: "云管家",
            active: "user",
          },
        },
        {
          path: "bindcoin",
          name: "bindcoin",
          component: () => import("@/views/user/bindcoin.vue"),
          meta: {
            title: "绑定信息",
            active: "user",
          },
        },
        {
          path: "ViewWithdrawalInformation",
          name: "ViewWithdrawalInformation",
          component: () => import("@/views/user/ViewWithdrawalInformation.vue"),
          meta: {
            title: "查看提现信息",
            active: "user",
          },
        },
        {
          path: "CoinRecharge",
          name: "CoinRecharge",
          component: () => import("@/views/user/CoinRecharge.vue"),
          meta: {
            title: "充值",
            active: "user",
          },
        },
        {
          path: "mixRecharge",
          name: "mixRecharge",
          component: () => import("@/views/user/mixRecharge.vue"),
          meta: {
            title: "混合充值",
            active: "user",
          },
        },
        {
          path: "preRecharge",
          name: "preRecharge",
          component: () => import("@/views/user/preRecharge.vue"),
          meta: {
            title: "预充值",
            active: "user",
          },
        },
        {
          path: "theAgency",
          name: "theAgency",
          component: () => import("@/views/user/theAgency.vue"),
          meta: {
            title: "代理中心",
            active: "user",
          },
        },
        {
          path: "myTeam",
          name: "myTeam",
          component: () => import("@/views/user/myTeam.vue"),
          meta: {
            title: "我的团队",
            active: "user",
          },
        },
        {
          path: "commissionRecord",
          name: "commissionRecord",
          component: () => import("@/views/user/commissionRecord.vue"),
          meta: {
            title: "佣金记录",
            active: "user",
          },
        },
        {
          path: "resultsTheQuery",
          name: "resultsTheQuery",
          component: () => import("@/views/user/resultsTheQuery.vue"),
          meta: {
            title: "业绩查询",
            active: "user",
          },
        },
        {
          path: "signInToLavishGifts/:id?",
          name: "signInToLavishGifts",
          component: () => import("@/views/user/signInToLavishGifts.vue"),
          meta: {
            title: "签到豪礼",
            active: "user",
          },
          props: true,
        },
        {
          path: "withdrawalCenter/:draw_mode?",
          name: "withdrawalCenter",
          component: () => import("@/views/user/withdrawalCenter.vue"),
          meta: {
            title: "提现中心",
            active: "user",
          },
        },
        {
          path: "withdraw",
          name: "withdraw",
          component: () => import("@/views/user/withdraw.vue"),
          meta: {
            title: "提现",
            active: "user",
          },
        },
        {
          path: "Shareface",
          name: "Shareface",
          component: () => import("@/views/user/Shareface"),
          meta: {
            title: "分享喜悦",
            active: "user",
          },
        },
        {
          path: "shareface2",
          name: "shareface2",
          component: () => import("@/views/user/shareface2"),
          meta: {
            title: "分享后页面",
            active: "user",
          },
        },
        {
          path: "userInfo/:user_id?/:index?",
          name: "userInfo",
          component: () => import("@/views/user/userInfo.vue"),
          meta: {
            title: "用户信息",
            active: "user",
          },
          props: true,
        },
        {
          path: "theFirsetToShare/:id?",
          name: "theFirsetToShare",
          component: () => import("@/views/user/theFirsetToShare.vue"),
          meta: {
            title: "首提分享金",
            active: "user",
          },
          props: true,
        },

        {
          path: "invite/:id?/:sign_id?",
          name: "invite",
          component: () => import("@/views/user/invite"),
          meta: {
            title: "邀请好友",
            active: "user",
          },
          props: true,
        },
        {
          path: "taskdetails",
          name: "taskdetails",
          component: () => import("@/views/user/taskdetails"),
          meta: {
            title: "我发布的",
            active: "user",
          },
        },
        {
          path: "myprops",
          name: "myprops",
          component: () => import("@/views/user/myprops"),
          meta: {
            title: "我的道具",
            active: "user",
          },
        },
        {
          path: "taskRecord",
          name: "taskRecord",
          component: () => import("@/views/user/taskRecord"),
          meta: {
            title: "任务记录",
            active: "user",
          },
        },
        {
          path: "postingDetails/:taskId?",
          name: "postingDetails",
          component: () => import("@/views/user/postingDetails.vue"),
          meta: {
            title: "发布详情",
          },
          props: true,
        },
        {
          path: "userTaskRecord/:id?",
          name: "userTaskRecord",
          component: () => import("@/views/user/userTaskRecord"),
          meta: {
            title: "某用户任务记录",
            active: "user",
          },
          props: true,
        },
        {
          path: "rechargelist",
          name: "rechargelist",
          component: () => import("@/views/user/rechargeList"),
          meta: {
            title: "充值记录",
            active: "user",
          },
          props: true,
        },
        {
          path: "withdrawlist",
          name: "withdrawlist",
          component: () => import("@/views/user/withdrawlist"),
          meta: {
            title: "某用户任务记录",
            active: "user",
          },
          props: true,
        },
        {
          path: "promotekid/:appRegister?",
          // path: 'promotekid',
          name: "promotekid",
          component: () => import("@/views/user/promotekid"),
          meta: {
            title: "邀请朋友",
            active: "user",
            // active:'promotekid'
          },
          props: true,
        },
        {
          path: "memberQuestions",
          name: "memberQuestions",
          component: () => import("@/views/user/memberQuestions"),
          meta: {
            title: "会员问题解答",
            active: "user",
          },
          props: true,
        },
        {
          path: "market",
          name: "market",
          component: () => import("@/views/user/market"),
          meta: {
            title: "商场任务",
            active: "user",
          },
        },
        {
          path: "details",
          name: "details",
          component: () => import("@/views/user/details"),
          meta: {
            title: "商场任务详情页面",
            active: "user",
          },
        },
        {
          path: "newpeople",
          name: "newpeople",
          component: () => import("@/views/user/newpeople"),
          meta: {
            title: "新手推广任务",
            active: "user",
          },
        },
        {
          path: "extension/:index?",
          name: "extension",
          component: () => import("@/views/user/extension"),
          meta: {
            title: "第一任务礼包",
            active: "user",
          },
          props: true,
        },
        {
          path: "Newcomer",
          name: "Newcomer",
          component: () => import("@/views/user/Newcomer"),
          meta: {
            title: "新手任务详情",
            active: "user",
          },
        },
        {
          path: "chatPage/:id?",
          name: "chatPage",
          component: () => import("@/views/user/chatPage"),
          meta: {
            title: "会员打招呼",
            active: "user",
          },
        },
        {
          path: "test1",
          name: "test1",
          component: () => import("@/views/user/test"),
          meta: {
            title: "测试1",
            active: "user",
          },
        },
        {
          path: "InviteSubordinates",
          name: "InviteSubordinates",
          component: () => import("@/views/user/InviteSubordinates"),
          meta: {
            title: "邀请下级",
            active: "user",
          },
        },
        {
          path: "Grabtask",
          name: "Grabtask",
          component: () => import("@/views/user/Grabtask"),
          meta: {
            title: "抢任务",
            active: "user",
          },
        },
      ],
    },
    {
      path: "/activityCenter",
      name: "activityCenter",
      component: () => import("@/views/user/activityCenter.vue"),
      meta: {
        title: "活动中心",
        active: "activityCenter",
      },
    },
    {
      path: "/404",
      name: "404",
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      path: "/openzd",
      component: () => import("@/views/user/zidong.vue"),
    },
    {
      path: "/aboutUs",
      component: () => import("@/views/info/aboutUs.vue"),
    },
    {
      path: "/introduced",
      component: () => import("@/views/info/introduced.vue"),
    },

  ],
});

export default router;
